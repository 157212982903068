






import Vue from 'vue'
import { PropOptions } from 'vue/types'
import { PostResume } from '~/types/post'

export default Vue.extend({
  name: 'FaqItem',

  props: {
    faq: {
      type: Object,
      required: true,
    } as PropOptions<PostResume>,
  },
})
