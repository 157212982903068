import { render, staticRenderFns } from "./FaqsSection.vue?vue&type=template&id=36d1abc0&scoped=true&"
import script from "./FaqsSection.vue?vue&type=script&lang=ts&"
export * from "./FaqsSection.vue?vue&type=script&lang=ts&"
import style0 from "./FaqsSection.vue?vue&type=style&index=0&id=36d1abc0&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36d1abc0",
  null
  
)

/* custom blocks */
import block0 from "./FaqsSection.vue?vue&type=custom&index=0&blockType=i18n&lang=yml"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FaqItem: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/FaqsSection/FaqItem/FaqItem.vue').default,PostByCategoryLink: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/PostByCategoryLink.vue').default,BaseStack: require('/codebuild/output/src1230313615/src/multidomain/src/ui/components/BaseStack.vue').default})
