










import Vue from 'vue'
import { PostResume } from '~/types/post'

export default Vue.extend({
  name: 'FaqsSection',
  data() {
    return {
      articles: [] as PostResume[],
    }
  },
  async fetch() {
    const articles = await this.$content('blog')
      .only(['title', 'date', 'tags', 'slug'])
      .where({ tags: 'preguntas-frecuentes' })
      .limit(8)
      .sortBy('date', 'desc')
      .fetch<PostResume>()
    this.articles = articles as PostResume[]
  },
})
